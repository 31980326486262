var errorMsg = "";

var callback = function (stackframes) {
    var stringifiedStack = stackframes.map(function (sf) {
        return sf.toString();
    }).join('\n');
    console.log(errorMsg + '\n' + stringifiedStack);
};

var errback = function (err) {
    console.log(err.message);
};

// Global error handler for javascript errors.
window.onerror = function (msg, url, line, col, error) {
    var extra = !col ? '' : '\ncolumn: ' + col;
    extra += !error ? '' : '\nerror: ' + error;
    //console.log("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);

   // alert("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
    if (error) {
        //console.log(msg + '\n');
        errorMsg = msg;
        StackTrace.fromError(error).then(callback).catch(errback);
    }
    else {
        console.log("Error: " + JSON.stringify(msg) + "\r\nurl: " + JSON.stringify(url) + "\r\nline: " + line + "\n" + extra);
    }

    return true; // returning true suppresses errors from certain browsers that automatically display them
};

// Global error handler for jquery ajax errors.
// Sometimes displays an HTML as a string, need to fix that.
$(document).ajaxError(function (event, xhr, ajaxOptions, exception) {

    var message = exception.message + "\r\n";
    //message += exception.stack + "\r\n";
    //var message = xhr.responseText; 

    try {
        message += JSON.parse(xhr.responseText);
    }
    catch (ex) {
        message += xhr.responseText;
    }

    errorMsg = message;
    StackTrace.fromError(exception).then(callback).catch(errback);

    //console.log("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
    //alert(message);
});
$(function () {
});

// A javascript class for common functions used across multiple pages
var commonUtilities = (function () {

    /**
    * Displays a loading gif over the entire page, fixed in the center of the screen.
    * This gif must be disposed of by calling commonUtilities.hideLoadingGif();
    *
    * @returns jquery - The loading gif container
    */
    function displayLoadingGif() {
        // Don't display a global loading gif if there is one already
        if ($('.loadingGif-container-global').length > 0) {
            $('.loadingGif-container-global').show();
            return $('.loadingGif-container-global');
        }

        var loadingGif = createLoadingGif(false);
        loadingGif.addClass('loadingGif-container-global');
        loadingGif.appendTo('body');

        return loadingGif;
    }

    /**
    * Hides the global loading gif
    */
    function hideLoadingGif() {
        $('.loadingGif-container-global').hide();
    }

    /**
    * Appends a loading gif to each element in the given jquery selector.
    * These elements must be disposed of by the calling call (usually as the result of the success function in an ajax call)
    * @param {jquery} selector - A jquery selector
    */
    function displayLoadingGifInElement(selector) {
        $(selector).each(function(index, element) {
            $(this).append(createLoadingGif(true));
        });
    }

    /**
    * Private function that creates the loading gif img and its div container
    * @param {Boolean} isSmall - True if the small loading gif should be used, false if the big loading gif should be used.
    * @returns {jquery} - The loading gif container
    */
    function createLoadingGif(isSmall) {
        var loadingGifContainer = $('<div class="loadingGif-container">');
        var src = isSmall ? "/Content/Images/Loading-small.gif" : "/Content/Images/Loading.gif";
        loadingGifContainer.append('<img src="' + src + '" alt="loading...">');
        return loadingGifContainer;
    }

    return {
        displayLoadingGif: displayLoadingGif,
        displayLoadingGifInElement: displayLoadingGifInElement,
        hideLoadingGif: hideLoadingGif
    };
})();
$(function () {

    // Slightly increase the size of the jquery autocomplete dropdown.
    // If I don't do this, highlighting an item sometimes causes that item to take up two lines instead of one, which is annoying.
    jQuery.ui.autocomplete.prototype._resizeMenu = function () {
        var ul = this.menu.element;
        ul.outerWidth(ul.outerWidth() + 10);
    };
});

var autocompleteMVC = (function () {

    // Get the autocomplete length from the web.config
    var autocompleteMinLength = getMinimumSuggestionBoxCharacters();

    // Synchronously retreives the minimum number of characters that need to be typed into an autocomplete box until it will display results
    function getMinimumSuggestionBoxCharacters() {
        var returnLength = 2; // Default to 2 if the call fails for some reason
        $.ajax({
            url: '/Home/SuggestionsBoxMinimumCharacters',
            dataType: 'json',
            async: false,
            success: function (data) {
                returnLength = data;
            }
        });
        return returnLength;
    }

    /**
    * Sets all elements matching the selector to be autocompletes, which use json to retreive their data
    * @param {String} inputSelector Any element matching this selector will be set as an autocomplete field 
    * @param {String} jsonURL The url to retreive the autocomplete data. MUST return a list of objects with two fields - modelKey and modelValue
    * @param {function} selectFunction The function to call when an item is selected in the autocomplete. Two parameters are passed in, the autocomplete element itself and the selected item
    */
    function setAutocomplete(inputSelector, jsonURL, selectFunction) {
        // Get the minimum length from the web.config file
        $(inputSelector).each(function (i, element) {
            element = $(element);
            element.autocomplete({
                source: function (request, response) {
                    var value = request.term;
                    if ((typeof value) === 'string')
                        value = value.trim();
                    $.getJSON(jsonURL, { value: value }, function (data) {
                        response($.map(data, function (item) {
                            return { label: item.modelValue, value: item.modelValue, id: item.modelKey };
                        }));
                    });
                },
                select: function (event, ui) {
                    selectFunction(element, ui);
                },
                change: function (event, ui) {
                    var value = $(element).val();
                    if ((typeof value) === 'string')
                        value = value.trim();

                    if (value.length > 0) {
                        $.getJSON(jsonURL, { value: value }, function (data) {
                            if (data.length > 0) {
                                $(element).val(data[0].modelValue);
                                selectFunction(element, { item: { label: data[0].modelValue, value: data[0].modelValue, id: data[0].modelKey } });
                            }
                        });
                    }
                },
                minLength: autocompleteMinLength,
                delay: 500,
                highlight: true,
                autoFocus: true,
            });
        });
    }

    return {
        setAutocomplete: setAutocomplete,
    };

})();
$(function () {
    // DataTables don't resize well on their on, force them to when the window is resized.
    var dataTablesResize = function () {
        $('.dataTable').each(function () {
            if ($(this).is(':visible'))
                $(this).width($(this).parent().innerWidth());
        });
    };

    $(window).on('resize', function () {
        dataTablesResize();
    });
});

var datatablesExtensions = (function () {

    /**
    * Adds multi column filtering into a table.
    * Looks for th elements with a class of searchableTableRow, adds an input field, and uses the field to search the table
    * See: https://datatables.net/examples/api/multi_filter.html
    *
    * @param {String} tableId The id of the DataTables table (without the # symbol)
    */
    function addMultiColumnFiltering(tableId) {
        // Add a text input to each column that has the searchableTableRow class
        $('#' + tableId).find('.searchableTableRow').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="searchableRowInput" placeholder="Search ' + title + '" />');
        });

        // A timeout so we don't flood the server with requests when typing a large amount of characters
        var delayedDatatablesSearch = 0;

        // Whenever one of the input fields we created are changed
        $('input.searchableRowInput').on('keyup', function () {

            // Reset the timeout
            clearTimeout(delayedDatatablesSearch);
            delayedDatatablesSearch = setTimeout(function () {
            
                // A variable to store the search as we build it
                var search = "";

                // DataTable
                var table = $('#' + tableId).DataTable();

                // Loop through every column and add it's search input value into the datatables search
                table.columns().every(function () {

                    var columnIndex = this.index();
                    // This index in the dom may be different from the DataTables index if there are hidden columns before this column
                    var domColumnInex = $(this.header()).index();

                    // Find last header row in our thead (which should have the search fields)
                    var lastHeaderRow = $('#' + tableId + ' thead tr').last();
                    // Find the th element that belongs to this column (i.e. the 8th column would be the 8th th in the row)
                    var thForColumn = lastHeaderRow.children('th').eq(domColumnInex);
                    // Find the input field for this column
                    var headerInput = thForColumn.children('.searchableRowInput');

                    if (headerInput.length > 0) {
                        // Get the search input value
                        var columnSearch = headerInput.val();

                        // Add the search with the value into the variable (creating it if it doesn't exist)
                        if (search.length === 0)
                            search = table.column(columnIndex).search(columnSearch);
                        else
                            search = search.column(columnIndex).search(columnSearch);
                    }
                });

                // Perform the search
                if (search !== undefined)
                    search.draw();

                clearTimeout(delayedDatatablesSearch);

            }, 500);
        });
    }

    /**
    * Adds a custom client side filter (a filter for a table without serverSide set to true) to the given table
    * Calls the given function to filter the table.
    * See: https://datatables.net/examples/plug-ins/range_filtering.html
    * and: https://www.datatables.net/manual/plug-ins/search
    *
    * @param {String} tableId The id of the DataTables table (without the # symbol)
    * @param {String} selector The jquery selector for the filter elements
    * @param {function} filterFunction The function that is called on each row and determines if the row is displayed or not.
    *                                  For parameters and an example, see: https://www.datatables.net/manual/plug-ins/search
    */
    function addCustomClientSideFilter(tableId, selector, filterFunction) {
        
        // A timeout so we don't flood the server with requests when typing a large amount of characters
        var delayedDatatablesSearch = 0;

        // When the selected element is typed into or changed, run the custom search after a timeout
        $(selector).each(function (index, element) {
            // The event we use to trigger the table search depends on the type of element.
            // We use keyup for text fields, as the change event only fires when they lose focus.
            // We use change for everything else
            var event = $(this).is('input:text, textarea') ? 'keyup' : 'change';

            $(this).on(event, function () {
                // Reset the timeout
                clearTimeout(delayedDatatablesSearch);
                delayedDatatablesSearch = setTimeout(function () {
                    // DataTable
                    var table = $('#' + tableId).DataTable();
                    table.draw();
                }, 500);
            });
            
        });

        // Run the custom function whenever a search is run on the table
        $.fn.dataTable.ext.search.push(
            function (settings, searchData, rowIndex, data) {
                return filterFunction(settings, searchData, rowIndex);
            }
        );
    }

    // An array of the selectors we're using as custom filters, we use the id and value as a json key/value pair and pass it into the controller
    var customServerSideFilterSelectors = [];

    /**
    * Adds a custom server side filter (a filter for a table with serverSide == true)
    * The data is passed into the controller in the format of { nameAttribute: value }
    * Throws an exception if the id attribute is not set for each element in the selector
    *
    * The server side filters must be added to the DataTables ajax call by calling getCustomServerSideFilters.
    * Example:
    * $('#myTable').DataTable({
    *     serverSide: true, // Use server side processing by getting data from ajax
    *     processing: true, // Display the 'processing...' text when making a server side call
    *     ajax: // Pass the data to the controller in the json format that ASP.NET MVC expects.
    *     {
    *        'url': 'Home/MyURL',
    *        'type': 'POST',
    *        'contentType': 'application/json; charset=utf-8',
    *        'data': function (data) {
    *            // Add the custom filters into the data passed to the controller
    *            data = datatablesExtensions.getCustomServerSideFilters(data);
    *            // Convert the data to a format the ASP.NET MVC can read
    *            return JSON.stringify(data);
    *        }
    *    },
    *
    *
    * See: http://datatables.net/examples/server_side/custom_vars.html
    *
    * @param {String} tableId The id of the DataTables table (without the # symbol)
    * @param {String} selector The jquery selector for the filter elements
    */
    function addCustomServerSideFilter(tableId, selector) {
        // We require an id attribute for each server side filter, as we need to post back the data
        $(selector).each(function () {
            if ($(this).attr('name').length === 0)
                throw new Error("All custom server side filters must have a name attribute");
        });

        // A timeout so we don't flood the server with requests when typing a large amount of characters
        var delayedDatatablesSearch = 0;

        // When the selected element is typed into or changed, run the custom search after a timeout
        $(selector).each(function (index, element) {
            // The event we use to trigger the table search depends on the type of element.
            // We use keyup for text fields, as the change event only fires when they lose focus.
            // We use change for everything else
            var event = $(this).is('input:text, textarea') ? 'keyup' : 'change';

            $(this).on(event, function () {
                // Reset the timeout
                clearTimeout(delayedDatatablesSearch);
                delayedDatatablesSearch = setTimeout(function () {
                    // DataTable
                    var table = $('#' + tableId).DataTable();
                    // Causes the table search to run
                    table.draw();
                }, 500);
            });

        });
        
        // Store the selector in an array, so we can retreive the value of these filters once the table is redrawn.
        // The data is returned from the method: getCustomServerSideFilters
        customServerSideFilterSelectors.push(selector);
    }

    /**
    * Adds the custom server side filters into the passed in DataTables data object in the format of:
    *    { id: value }
    * where id is the filter element's id and value is the result of .val() for input elements, or a boolean for checkboxes.
    *
    * Look at the documentation of addCustomServerSideFilter for an example
    *
    * @param {Object} dataObject The DataTables data object (the function parameter, not the option).
    *                            See: https://datatables.net/reference/option/ajax.data
    */
    function getCustomServerSideFilters(dataObject) {

        var addToDataObject = function (index, element) {
            var key = $(this).attr('name');
            var value = '';

            // Get the value of the input, we to handle checkboxes specifically as .val() works differently for checkboxes
            if ($(element).is(':checkbox'))
                value = $(this).is(':checked');
            else
                value = $(this).val();

            // Add the key value pair into the Datatables object
            dataObject[key] = value;
        };

        // Loop through all the custom server side filters, add their values to the array passed in, using their name attribute as the key
        for (var i = 0; i < customServerSideFilterSelectors.length; ++i) {
            $(customServerSideFilterSelectors[i]).each(addToDataObject);
        }

        return dataObject;
    }

    return {
        
        addMultiColumnFiltering: addMultiColumnFiltering,
        addCustomClientSideFilter: addCustomClientSideFilter,
        addCustomServerSideFilter: addCustomServerSideFilter,
        getCustomServerSideFilters: getCustomServerSideFilters,
    };

})();
/* Tell JsHint about the other JS libraries so we don't get warnings*/
/*global commonDialogs */
/*global autocompleteMVC */
/*global datatablesExtensions */
/*global ingredientsManager */

$(function () {
    
    var ajaxSource = "";

    // Using the title for determining what the ajax method is somewhat hacky.
    var title = document.title;
    if (title === 'New Ingredients')
        ajaxSource = 'AjaxLoadNewIngredients';
    else if (title === "Backlog")
        ajaxSource = 'AjaxLoadBacklogIngredients';
    else if (title === "All Ingredients")
        ajaxSource = 'AjaxLoadAllIngredients';

    $('#dashboardTable').DataTable({
        serverSide: true, // Use server side processing by getting data from ajax
        ajax: // Pass the data to the controller in the json format that ASP.NET MVC expects.
        {
            url: ajaxSource,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: function (data) {
                // Add the custom filters into the data passed to the controller
                data = datatablesExtensions.getCustomServerSideFilters(data);
                // Convert the data to a format the ASP.NET MVC can read
                return JSON.stringify(data);
            }
        },
        processing: true, // Display the 'processing...' text when making a server side call
        sortCellsTop: true, // Place the sort buttons on the top-most headers, instead of the search row
        autoWidth: false, // Stop datatables from automatically picking the widths, we'll set them for our columns and rely on html to resize if they're too large or small
        stateSave: true, // Save the state (# of entries dropdown, current page, sorting, etc.)
        stateSaveParams: function (settings, data) { // Don't save the current search
            delete data.search;
            // As we're using multi-column filtering, we need to remove the search parameter for each column
            for (var i = 0; i < data.columns.length; i++) {
                delete data.columns[i].search;
            }
            delete data.visible;
        },
        columnDefs: [
            // Edit column - set to be an href that links to the Edit screen for the generic rxcui in column index 1
            {
                targets: 'dashboard-editColumn',
                name: 'dashboard-editColumn',
                searchable: false,
                sortable: false,
                width: '25px',
                render: function (data, type, row) {
                    return '<a href=\"/IngredientsManager/Ingredient/Edit?genericRXCUI=' + row.GenericRXCUI + '&returnToUrl=true\">Edit</a>';
                }
            },

            {
                targets: 'dashboard-genericRXCUIColumn',
                name: 'dashboard-genericRXCUIColumn',
                data: 'GenericRXCUI',
                width: '45px',
            },

            {
                targets: 'dashboard-fillsColumn',
                name: 'dashboard-fillsColumn',
                data: 'Fills',
                width: '50px',
            },

            {
                targets: 'dashboard-firstFillColumn',
                name: 'dashboard-firstFillColumn',
                data: 'FirstFillString',
                width: '65px',
            },

            {
                targets: 'dashboard-lastFillColumn',
                name: 'dashboard-lastFillColumn',
                data: 'LastFillString',
                width: '65px',
            },

            {
                targets: 'dashboard-ingredientNameColumn',
                name: 'dashboard-ingredientNameColumn',
                data: 'IngredientName',
                width: '150px',
            },


            {
                targets: 'dashboard-abusePotentialColumn',
                name: 'dashboard-abusePotentialColumn',
                data: 'AbusePotDescription',
                width: '130px',
            },

            {
                targets: 'dashboard-isAvailableColumn',
                name: 'dashboard-isAvailableColumn',
                data: 'IsAvailableString',
                className: 'centerText', // Center the text horizontally
                width: '35px',
            },

            {
                targets: 'dashboard-isChronicColumn',
                name: 'dashboard-isChronicColumn',
                data: 'IsChronicString',
                className: 'centerText', // Center the text horizontally
                width: '35px',
            },

            {
                targets: 'dashboard-isScheduledColumn',
                name: 'dashboard-isScheduledColumn',
                data: 'IsScheduledString',
                className: 'centerText', // Center the text horizontally
                width: '35px',
            },

            {
                targets: 'dashboard-isVaccineColumn',
                name: 'dashboard-isVaccineColumn',
                data: 'IsVaccineString',
                className: 'centerText', // Center the text horizontally
                width: '35px',
            },

            {
                targets: 'dashboard-morphineEquivalentColumn',
                name: 'dashboard-morphineEquivalentColumn',
                data: 'MorphineEquivalent',
                width: '35px',
                render: function (data, type, row) {
                    if (row.AbusePotDescription.toLowerCase().indexOf("opioids") != -1)
                        return data;
                    else
                        return 'n/a';
                }
            },

            {
                targets: 'dashboard-doseEquivalentColumn',
                name: 'dashboard-doseEquivalentColumn',
                data: 'DoseEquivalent',
                width: '35px',
            },

            // BaseIngredientName column - If the GenericRXCUI is different from the BaseIngredientRXCUI, set to a link to the edit screen
            //                             for the base ingredient. Otherwise, just leave it as text.
            {
                targets: 'dashboard-baseIngredientNameColumn',
                name: 'dashboard-baseIngredientNameColumn',
                data: 'BaseIngredientName',
                // Make a link to the base ingredient if the BaseIngredientRXCUI is different then this ingredient's GenericRXCUI
                render: function (data, type, row) {
                    if (row.BaseIngredientRXCUI !== row.GenericRXCUI)
                        return '<a href=\"/IngredientsManager/Ingredient/Edit?genericRXCUI=' + row.BaseIngredientRXCUI + '&returnToUrl=true\">' + data + '</a>';
                    else
                        return data;
                }
            },

            // BaseIngredientRXCUI - hide this column, used for comparing the GenericRXCUI to the BaseIngredientRXCUI
            {
                targets: 'dashboard-baseIngredientRXCUIColumn',
                name: 'dashboard-baseIngredientRXCUIColumn',
                data: 'BaseIngredientRXCUI',
                visible: false,

            }
        ]
    });

    setupColumnFiltering("dashboardTable");

    $('.dashboard-export').on('click', function () {
        //var json = $("#dashboardTable").dataTable()._fnAjaxParameters({});

        //// Add the custom filters into the data passed to the controller
        //json = datatablesExtensions.getCustomServerSideFilters(json);

        // Can't use ajax to get a file, need to use window.location. This doesn't actually redirect the user off the page.
        window.location.href = "/IngredientsManager/Dashboard/ExportIngredients";

        return false;
    });
});

var dashboard = (function () {

    return {
    };
})();

/* Tell JsHint about the other JS libraries so we don't get warnings*/
/*global commonDialogs */
/*global autocompleteMVC */
/*global datatablesExtensions */

$(function () {
});

var ingredientsManager = (function () {
    /**
    * Adds a filter to the Number Of Ingredients column to use that column to search for ingredient names that match the text in the table.
    * @param {String} tableId - The ID attribute for the table to filter, without the # character
    * @param {String} jsonURL - The url to return records that contain the ingredient name in the search field. Must return a list of strings matching the primary key in the table.
    */
    function addIngredientNameCustomClientFilter(tableId, jsonURL) {
        var primaryKeyList;

        var primaryKeyColumnIndex = $('#' + tableId + ' th.primaryKeyRow').index();
        if (primaryKeyColumnIndex === -1)
            throw new Error('No th with class of primaryKeyRow in table ' + tableId);

        datatablesExtensions.addCustomClientSideFilter(tableId, '#searchIngredientsInput', function (settings, data, dataIndex) {

            // If this is the first row we're running the search on, load the list of primary keys that contain the IngredientName.
            if (dataIndex === 0)
                primaryKeyList = null;

            // Get the IngredientName entered into the search field
            var ingredientName = $('#searchIngredientsInput').val().trim();
            if (ingredientName.length === 0)
                return true;

            if (primaryKeyList == null) {
                $.ajax({
                    url: jsonURL,
                    data: { ingredientName: ingredientName },
                    dataType: 'json',
                    async: false, // Can't run as async here, as we need to return from this function for dataTables
                    success: function (data) {
                        primaryKeyList = data;
                    }
                });
            }


            // If our array contains the primary key for this row, return true. Otherwise, return false
            if ($.inArray(data[primaryKeyColumnIndex], primaryKeyList) >= 0)
                return true;

            return false;
        });
    }    

    return {
        addIngredientNameCustomClientFilter: addIngredientNameCustomClientFilter
    };
})();